// libraries
import React from 'react';
// components
import Layout from 'components/Layout';
import { SEO } from 'components/SEO';

const NotFoundPage = () => {
    return (
        <Layout>
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    );
};

export default NotFoundPage;

export const Head = () => {
    return <SEO title="Page not found" />;
};
